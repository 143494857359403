import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <div>
      <p>没找到该页面</p>
      <Link to="/">回首页</Link>
    </div>
  )
}

export default NotFoundPage
